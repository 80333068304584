<template>
  <div>
    <v-card>
      <v-card-title>
        Detalle de la orden <span class="px-2 txt-primary"> {{ order.numero_orden }} </span>
        <div
          class="background-element-secondary pa-1"
          style="color:white;"
        >
          {{ order.estatus_orden.dato }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <div v-if="isCustomer">
                <h2 class="txt-secondary"> {{ order.distribuidor.nombre_distribuidor }} </h2>
                <p class="body-2">
                  Contacto: {{ order.distribuidor.datos_contacto.primer_nombre }} {{ order.distribuidor.datos_contacto.apellido_paterno }} <br>
                  Correo: {{ order.distribuidor.datos_contacto.email }} <br>
                  Teléfono: {{ order.distribuidor.datos_contacto.telefono_movil }} 
                </p>
              </div>
              <div v-else>
                <h2 class="txt-secondary"> {{ order.cliente.datos_personales.primer_nombre }} {{ order.cliente.datos_personales.apellido_paterno }}</h2>
                <p class="body-2">
                  Correo: {{ order.cliente.datos_personales.email }} <br>
                  Teléfono: {{ order.cliente.datos_personales.telefono_movil }} 
                </p>
              </div>
              <v-divider></v-divider>
              <p class="h6">Productos</p>
              <v-simple-table 
                v-if="order.detalles.length"
                dense>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="detalle in order.detalles"
                      :key="detalle.id"
                    >
                      <td class="text-right px-2">{{ detalle.unidades }}</td>
                      <td class="px-0" style="width: 210px">{{ detalle.producto.descripcion_corta }}</td>
                      <td class="text-right px-0">$ {{ detalle.precio_unitario }}</td>
                      <td class="text-right px-0">$ {{ detalle.sub_total }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <p 
                v-else
                class="body-2"
              >
                No se pudieron obtener los productos
              </p>
              <v-simple-table 
                class="my-4 body-2 font-weight-bold prices" 
                dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="no-border">Subtotal</td>
                      <td class="text-right no-border">$ {{ order.sub_total }}</td>
                    </tr>
                    <tr>
                      <td class="no-border">Impuesto</td>
                      <td class="text-right no-border">$ {{ order.total_impuestos }}</td>
                    </tr>
                    <tr>
                      <td class="no-border">Envío</td>
                      <td class="text-right no-border">$ {{ order.costo_envio }}</td>
                    </tr>
                    <tr>
                      <td class="no-border">Total orden</td>
                      <td class="text-right no-border">$ {{ order.total_orden }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-divider></v-divider>
              <div v-if="showCancellation">
                <p class="h6">Cancelación</p>
                <p
                  v-if="cancellation" 
                  class="body-2">
                  Estatus: {{ cancellation.estatus_cancelacion.dato }} <br>
                  Solicitado por: {{ cancellation.solicitado_por_cliente ? 'Cliente' : 'Distribuidor' }} <br>
                  Motivo: {{ cancellation.motivo_cancelacion.dato }} <br>
                  Comentarios: {{ cancellation.comentarios }}
                </p>
                <p
                  v-else 
                  class="body-2">
                  No se pudo obtener la información sobre la cancelación
                </p>
              </div>
              <v-row
                v-if="!isCustomer"
                class="py-2">
                <v-col md="5">
                  <v-btn
                    tile
                    color="primary"
                    :disabled="order.detalles.length === 0"
                    @click="downloadPackinSlip"
                  >
                    <v-icon left>
                      mdi-download
                    </v-icon>
                    Packing slip
                  </v-btn>
                </v-col>
                <v-col md="5">
                  <v-btn
                    tile
                    color="primary"
                    :disabled="order.detalles.length === 0"
                    @click="downloadPickPackList"
                  >
                    <v-icon left>
                      mdi-download
                    </v-icon>
                    Pick pack list
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <p class="h6">Dirección de envío</p>
              <p 
                v-if="order.direccion_envio" 
                class="body-2"
              >
                Calle {{ order.direccion_envio.calle }} 
                #{{ order.direccion_envio.numero_exterior }}-{{ order.direccion_envio.numero_interior }}, 
                Col. {{ order.direccion_envio.colonia }}, 
                C.P. {{ order.direccion_envio.codigo_postal }}, 
                {{ order.direccion_envio.ciudad }}, 
                {{ order.direccion_envio.estado }} 
              </p>
              <p 
                v-else
                class="body-2"
              >
                Dirección de envío no definida
              </p>
              
              <static-delivery-address
                v-if="isCustomer" 
                :customerAddress="order.direccion_envio" 
              />
              <customer-delivery-address
                v-else 
                :customerAddress="order.direccion_envio"
                :showCustomerAddress="order.direccion_envio ? true : false"
                :addClickEvent="false"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <div v-if="!isCustomer">
      <packing-slip
        ref="packingslip"
        v-show="false"
        :order="order"
      />
      <pick-pack-list 
        ref="pickpacklist"
        v-show="false"
        :order="order"
      />
    </div>
    
  </div>
</template>

<script>
import jsPDF from 'jspdf'
export default {
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    isCustomer: {
      type: Boolean,
      default: true
    },
    cancellation: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    CustomerDeliveryAddress: () => import('./Maps/CustomerDeliveryAddress'),
    StaticDeliveryAddress: () => import('./Maps/StaticDeliveryAddress'),
    PackingSlip: () => import('./pdfs/PackingSlip'),
    PickPackList: () => import('./pdfs/PickPackList.vue'),
  },
  computed: {
    showCancellation() {
      return this.order.estatus_orden && this.order.estatus_orden.dato === 'Cancelada'
    }
  },
  methods: {
    downloadPackinSlip() {
      const doc = new jsPDF('p', 'pt', 'A4')
      const packHtml = this.$refs.packingslip.$vnode.elm.innerHTML
      const docName = 'packing-slip.pdf'
      //console.log('packHtml', packHtml);
      doc.html(packHtml, {
        callback: function (doc) {
          doc.save(docName);
        },
        x: 10,
        y: 10
      });
    },
    downloadPickPackList() {
      const doc = new jsPDF('p', 'pt', 'A4')
      const packHtml = this.$refs.pickpacklist.$vnode.elm.innerHTML
      const docName = 'pick-pack-list.pdf'
      //console.log('packHtml', packHtml);
      doc.html(packHtml, {
        callback: function (doc) {
          doc.save(docName);
        },
        x: 10,
        y: 10
      });
    },
  }
}
</script>

<style scoped>
.prices {
  vertical-align: bottom;
  color: rgba(0,0,0,.6);
  pointer-events: none;
}
.no-border {
  border: none !important;
}
</style>